import(/* webpackMode: "eager" */ "/home/runner/work/hlr-webshop-frontend/hlr-webshop-frontend/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/hlr-webshop-frontend/hlr-webshop-frontend/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/hlr-webshop-frontend/hlr-webshop-frontend/src/icons/quote_right.svg");
;
import(/* webpackMode: "eager", webpackExports: ["Carousel"] */ "/home/runner/work/hlr-webshop-frontend/hlr-webshop-frontend/src/strapiComponents/Carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Navbar"] */ "/home/runner/work/hlr-webshop-frontend/hlr-webshop-frontend/src/strapiComponents/Navbar.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/hlr-webshop-frontend/hlr-webshop-frontend/src/strapiComponents/StrapiRichText.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ArticleSection"] */ "/home/runner/work/hlr-webshop-frontend/hlr-webshop-frontend/src/strapiSections/ArticleSection/ArticleSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BuyHeartstarterSection"] */ "/home/runner/work/hlr-webshop-frontend/hlr-webshop-frontend/src/strapiSections/BuyHeartstarterSection/BuyHeartstarterSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CheckoutSection"] */ "/home/runner/work/hlr-webshop-frontend/hlr-webshop-frontend/src/strapiSections/CheckoutSection/CheckoutSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InstructorClassForm"] */ "/home/runner/work/hlr-webshop-frontend/hlr-webshop-frontend/src/strapiSections/ClassFormSection/InstructorClassForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContactForm"] */ "/home/runner/work/hlr-webshop-frontend/hlr-webshop-frontend/src/strapiSections/ContactSection/ContactForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DetailHeaderSection"] */ "/home/runner/work/hlr-webshop-frontend/hlr-webshop-frontend/src/strapiSections/DetailHeaderSection/DetailHeaderSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FAQSection"] */ "/home/runner/work/hlr-webshop-frontend/hlr-webshop-frontend/src/strapiSections/FAQSection/FAQSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeroCard"] */ "/home/runner/work/hlr-webshop-frontend/hlr-webshop-frontend/src/strapiSections/HeroBannerSection/HeroCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LimeForm"] */ "/home/runner/work/hlr-webshop-frontend/hlr-webshop-frontend/src/strapiSections/LimeSection/LimeForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OrderConfirmedSection"] */ "/home/runner/work/hlr-webshop-frontend/hlr-webshop-frontend/src/strapiSections/OrderConfirmedSection/OrderConfirmedSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PasswordSection"] */ "/home/runner/work/hlr-webshop-frontend/hlr-webshop-frontend/src/strapiSections/PasswordSection/PasswordSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PopularProductsSection"] */ "/home/runner/work/hlr-webshop-frontend/hlr-webshop-frontend/src/strapiSections/PopularProductsSection/PopularProductsSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProfileSection"] */ "/home/runner/work/hlr-webshop-frontend/hlr-webshop-frontend/src/strapiSections/ProfileSection/ProfileSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RentHeartstarterSection"] */ "/home/runner/work/hlr-webshop-frontend/hlr-webshop-frontend/src/strapiSections/RentHeartstarterSection/RentHeartstarterSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SupportSection"] */ "/home/runner/work/hlr-webshop-frontend/hlr-webshop-frontend/src/strapiSections/SupportSection/SupportSection.tsx");
